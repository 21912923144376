// import ComingSoon from "./ComingSoon";
import "./ServicesFull.css";
import { HashLink } from "react-router-hash-link";
import GabrielClements from "./images/Screenshot 2024-08-14 at 4.03.25 PM.png";
import Apex from "./images/Screenshot 2024-11-13 at 9.53.34 PM.png";
import BMDetail from "./images/Screenshot 2024-11-13 at 9.54.19 PM.png";
import { NavLink, Link } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
// import { MdOutlineMail } from "react-icons/md";
// import { RiToolsFill } from "react-icons/ri";




function HowItWorks() {
    return(
        <div id="HowItWorks">
            <h2>How it works</h2>
            <div className="step">
                <h5>Free Consultation</h5>
                <div>
                    We discuss your business, your goals, what you would like your website to do, and what potential third-party services we might need, what assets we might need, potential websites that you would like us to use as inspiration.

                    <br></br>
                    <br></br>
                    I will then design a wireframe that would show a general idea of what the website will look like and how it will function. I can also, then, give a quote on how much the service will be.
                </div>
            </div>
            <div className="step">
                <h5>Build Phase</h5>
                <div>
                    Once you decide you would like to work together, we will then start the build phase. After the first week, you will have access to a temporary url which you can use to interact with your website and to see the progress that has been made so far. In addition, you will be able to contact me via my email and we will have weekly zoom meetings in which we can discuss the progress, bounce ideas, and give feedback.
                </div>
            </div>
            <div className="step">
                <h5>Deployment</h5>
                <div>Your website goes live!</div>
            </div>
        </div>
    )
}

function WebsitesGalore() {
    return(
        <div id="WebsitesGalore">
            <h2>Websites Galore</h2>
            <div className="website-block">
                <div className="website">
                    <img src={GabrielClements} alt="Gabriel Clements" />
                    <Link to="#" className="name">Portfolio - Gabriel Clements</Link>
                    <NavLink to="#" className="view-details">View Details</NavLink>
                </div>
                <div className="website">
                    <img src={BMDetail} alt="BmDetailServices" />
                    <Link to="#" className="name">BM Detail Services</Link>
                    <NavLink to="#" className="view-details">View Details</NavLink>
                </div>
                <div className="website">
                    <img src={Apex} alt="Apex Overhead" />
                    <Link to="#" className="name">Apex Overhead</Link>
                    <NavLink to="/#" className="view-details">View Details</NavLink>
                </div>
            </div>
        </div>
    )
}

function WhatIsIncluded() {
    const packages = [
        "Free Consultation",
        "ASGI Approved, Responsice Design",
        "Basic SEO setup",
        "Website Document",
        "Content Writing",
        "Weekly Update Meetings"
    ]
    return(
        <div id="WhatIsIncluded">
            <h2>What's Included:</h2>
            <ul>
                {packages.map((unit => {
                    return(
                        <li><FaPlus className="plus-btn" size="15px"/> {unit}</li>
                    )
                }))}
            </ul>
        </div>
    )
}

// function AddOns() {
//     return(
//         <div id="AddOns">
//             <h2>Add Ons</h2>
//             <ul className="add-ons">
//                 <li>
//                     <MdOutlineMail size="50px"/>
//                     Email Services
//                 </li>
//                 <li>
//                     <RiToolsFill size="50px"/>
//                     Website Maintenance
//                 </li>
//             </ul>
//         </div>
//     )
// }

function ServicesFull() {
    return(
        <div id="ServicesFull">
            {/* Services Full */}
            <h1>Web Development Services</h1>
            <nav>
                <ul className="nav">
                    <HashLink to="#HowItWorks">
                        <li>How it works</li>
                    </HashLink>
                    <HashLink to="#WebsitesGalore">
                        <li>Websites Galore</li>
                    </HashLink>
                    <HashLink to="#WhatIsIncluded">
                        <li>What's included</li>
                    </HashLink>
                    <HashLink to="#AddOns">
                        <li>Add-ons</li>
                    </HashLink>
                    <li>FAQ</li>
                </ul>
            </nav>
            <HowItWorks />
            <WebsitesGalore />
            <WhatIsIncluded />
            {/* <AddOns /> */}


        </div>
    )
}

export default ServicesFull;
